<template>
  <Header/>
  <div class="container">
    <div class="box-scroll">
        <div class="box-content-center">
            <h1>FAQ</h1>
            <div class="content-faq">
                <div class="box-search-faq">
                    <div class="b-input-search">
                      <input type="text" class="form-control" placeholder="Search questions..." v-model="filter_question">
                    </div>
                    <div class="b-icon"><i class="fas fa-search"></i></div>
                </div>
                <div class="box-qa" v-for="(value, key) in datas" :key="'faq_'+key" >
                    <div class="box-question" :id="'faq_question_'+key">
                        <div class="b-text-q"><span v-html="value.Question"></span></div>
                        <div class="b-icon" @click="toggle(key)"> <span class="b-arrow-up">
                           <i class="fas fa-chevron-down" :id="'arrow-'+key"></i>
                        </span></div>
                    </div>
                    <div class="box-answer" :id="'faq_answer_'+key" >
                        <div class="box-body-answer"  v-html="value.Answer"></div>
                    </div>
                </div>
                <div class="box-section-bt">
                    <span>Can't find what you are looking for?</span>
                    <button class="btn btn-bg-orange btn-custom"  data-bs-toggle="modal" data-bs-target="#modal-feedback">Contact us</button>
                </div>

            </div>
        </div>
    </div>

</div>
<ModalFeedback/>
<Footer/>
</template>

<script>
import Header from '@/components/Layout/Header.vue'
import Footer from '@/components/Layout/Footer.vue'
import ModalFeedback from '@/components/elements/modal_feedback.vue'
import {datas_for_faq} from '@/utils/faq'

export default {
    name : "faq",
    components : {
      Header,
      Footer,
      ModalFeedback
    },
    data(){
      return {
        filter_question : ''
      }
    },
    computed : {
        datas(){
            return this.filter_question ? datas_for_faq.filter(faq => faq.Question.toLowerCase().includes(this.filter_question.toLowerCase())) : datas_for_faq
        }
    },
    methods : {
      toggle(id){
        let element_question = document.getElementById('faq_question_'+id);
        element_question.classList.toggle('active');
        let element_answer = document.getElementById('faq_answer_'+id);
        element_answer.classList.toggle('open');
        let element_arrow = document.getElementById('arrow-'+id);
        element_arrow.classList.toggle('fa-chevron-up');
      }
    }
}
</script>

<style scoped src="../assets/css/faq.css"></style>