export const datas_for_faq =[
        {
            "Question": "Who can I contact if my question isn't answered here?",
            "Answer": "If your question isn't answered here, please email us at <strong>Global@prioritis.com</strong> and a member of our team will get back to you.",
        },
        {
            "Question": "What markets and agencies does Prismaccess cover?",
            "Answer": "The markets covered by Prismaccess can be seen on our search page, which are frequently increasing. This includes (but is not limited to) US, EU5 (France, Germany, Italy, Spain, England), Scotland, Sweden, Canada, Australia, Japan, South Korea, and Brazil.",
        },
        {
            "Question": "What markets and agencies will be added to Prismaccess in the future?",
            "Answer": "The markets covered by Prismaccess can be seen on our search page, which are frequently increasing. Prioritis will email you when new markets are added to Prismaccess.",
        },
        {
            "Question": "Which therapeutic areas are covered by Prismaccess?",
            "Answer": "All therapeutic areas are covered by Prismaccess. Please note that some licenses are purchased with access to only selected therapeutic areas.",
        },
        {
            "Question": "Does Prismaccess include biosimilars, generics, and hybrid drugs?",
            "Answer": "Prismaccess includes all assessments for biosimilar and hybrid drugs for all markets. Generics and biosimilars are partly covered (when there is advice from the TC) in France.",
        },
        {
            "Question": "Does Prismaccess cover all assessments, including reassessments, new indications, and additional range?",
            "Answer": "Yes, Prismaccess includes all reassessments, new indications, extensions to indication and additional range assessments.",
        },
        {
            "Question": "Does Prismaccess include ongoing assessments which have not yet concluded?",
            "Answer": "Yes, ongoing assessments are included for all markets/agencies where this information is available.",
        },
        {
            "Question": "Does Prismaccess include medical devices?",
            "Answer": "No, medical devices are not covered by Prismaccess.",
        },
        {
            "Question": "How frequently is Prismaccess updated?",
            "Answer": "Prismaccess is updated daily. New assessments are incorporated within 24 hours of publication.",
        },
        {
            "Question": "Who populates the information in Prismaccess?",
            "Answer": "Prismaccess is populated by our team of local experts based around the world who are supported by our innovative IT team. Our team comprises of pharmacists, health economists, scientific and IT experts.",
        },
        {
            "Question": "What sources are used by Prismaccess?",
            "Answer": "The official Regulatory and HTA bodies are used as sources for Prismacces. Data from clinicaltrials.gov is also used to supplement clinical trial information. In particular strict circumstances, manufacturer press releases may also be used.",
        },
        {
            "Question": "Data in Prismaccess is incorrect. How can I inform you?",
            "Answer": "Prioritis strive to keep the data in Prismaccess as accurate as possible and only use data from official sources. In the unlikely event that you have spotted an error please contact us at Global@prioritis.com",
        },
        {
            "Question": "How can I download or export my search results?",
            "Answer": "You can download the data from images and data tables in the search results, in addition to each individual assessments. <br>To download your search results, please click on the downward arrow(↓).",
        },
        {
            "Question": "Can I recieve a notification if there is an update for a particular therapy/market/therapeutic area?",
            "Answer": "To be automatically kept update on a product, therapeutic area or market, you can set up an email alert. This will email you within 24h of final publication of the official website with a click link to the Prismaccess summary from your inbox. To set up your email alerts please click here, or email Global@prioritis.com",
        },
        {
            "Question": "Can Prismaccess translate documents to English?",
            "Answer": "Yes, key documents in French, German, Italian, and Spanish are can be translated to English by our tranlsation feature. While the translation feature is useful, please note that the original document alone is authentic. Prioritis will not be held responsible for any imprecise translation.",
        },
        {
            "Question": "How are the documents that are translated through Prismaccess validated?",
            "Answer": "Document translation is conducted using AI tailored for medical lexicon. While the translation feature is useful, please note that the original document alone is authentic. Prioritis will not be held responsible for any imprecise translation.",
        },
        {
            "Question": "How is delay to reimbursement calculated?",
            "Answer": "Delay to reimbursement is calculated as the number of days from Marketing authorisation to reimbursement.",
        },
        {
            "Question": "Why is reimbursement in Germany before the HTA assessment?",
            "Answer": "In Germany, products are reimbursed with free pricing for one year following launch. During this time, the G-BA conduct a HTA assessment and pricing negotiations occur which come into effect once the year of free pricing expires. If the pricing negotiations have concluded by the time free pricing has expired, then the agreed price will be enforced retrospectively when negotiations have concluded.",
        },
        {
            "Question": "Shouldn't the reimbursement date in Germany be the same as the Marketing authorisation date at the EMA/EC?",
            "Answer": "Reimbursement is automatically granted at the time of launch which is defined as the time when the list price is published (on Lauertaxe). At the time of launch, a dossier must be submitted to the G-BA (there are only a few exceptions where no submission is needed). Once launched, all products are reimbursed in Germany according to the approved EC indication (free pricing for the time of the evaluation & negotiation). The launch date is set to either the 1st or the 15th of the month. For this reason, the launch date is the reimbursment date in Prismaccess.",
        },
        {
            "Question": "Can I filter my search results?",
            "Answer": "Yes, in the Overview or Expert landscape, please click on 'Filters' in the top left. You can filter by treatment line (for oncology products), decision, orphan status, and many more KPIs.",
        },
        {
            "Question": "Does Prismaccess include information regarding the reason why an assessment has been approved or rejected?",
            "Answer": "Yes, Prioritis extract key drivers for a decision which is in the 'Rationale and comment' section of each assessment. In addition, limitations regarding the patient population, prescribing & dispensing conditions, access scheme, and trial outcomes are presented in systematically for all HTA assessments.",
        },
        {
            "Question": "Do you have an explanation of the HTA and reimbursement process for each country?",
            "Answer": "Prioritis have provided a HTA roadmap for the EU5 which explains the HTA, pricing, and reimbursement processes. The link to this can be found, here.",
        },
        {
            "Question": "Who can I contact to organise a training for myself/my team/my organisation?",
            "Answer": "Prioritis regularly conducts trainings for its clients to ensure its users can experience and benefit from the full value Prismaccess provides. If you would like to request a training please email Global@prioritis.com",
        },
        {
            "Question": "Does Prismaccess include health economics?",
            "Answer": "Prismaccess includes thorough summaries on the health economic in HTA assessments, wherever available. For an even more comprehensive analysis, we would like to refer you to our other platform, PrismHEOR. For more information, please email us at Global@prioritis.com",
        },
        {
            "Question": "Does Prismaccess include pricing data?",
            "Answer": "While Prismaccess is not a pricing database, it does capture public prices and their sources.",
        },
        {
            "Question": "Does Prismaccess include documents published prior to the final decision (e.g. committee papers)?",
            "Answer": "Yes, committee reports and company replies (such as those seen at NICE) are provided in the key documents section, wherever available.",
        },
        {
            "Question": "The HTA decision for Italy is N/A. Are there any plans to include this?",
            "Answer": "Italy does not publish HTA information at the national level which mean that it can not be incorporated. All information published by AIFA is captured, including the reimbursement status, public price, and early access information.",
        },
        {
            "Question": "Can Prioritis conduct a data extraction, analysis or some research for me?",
            "Answer": "Yes, the Prioritis consulting team is conducts bespoke extractions, analyses, and research for its clients. For more information, please contact Global@prioritis.com",
        },
        {
            "Question": "Can we incorporate the data from Prismaccess into our own intranet/software/dashboard?",
            "Answer": "Yes, Prioritis offer an API solution which allows our data to connect and update external systems. For more information, please contact Global@prioritis.com",
        },
        {
            "Question": "Does Prioritis include information regarding availability on Medicare/Medicaid/insurance plans from the US?",
            "Answer": "No, Prismaccess does not cover this information.",
        },
        {
            "Question": "How can I search by clinical trial?",
            "Answer": "To search by clinical trial, please click the downdown menu called 'Clinical studies' on the search page. In this menu you can search by NCT number, trial phase, endpoints, and single arm trials, in addition to therapeutic area, countries and drugs as seen in the main search.",
        },
        {
            "Question": "What clinical trials are included in Prismaccess?",
            "Answer": "Prismaccess includes all clinical trials which were used for as part of the efficacy data package in the submission. This does not include Phase 1 or 1/2 trials (unless it was the only trial in the efficacy data package), or where the NCT number could not be identified. In addition, it does not include trials only used in indirect treatment comparisons.",
        },
        {
            "Question": "How can I report a missing/bugging/broken feature or link on Prismaccess, or provide feedback?",
            "Answer": "Please report any feedback or technical glitches to us at Global@prioritis.com",
        }
    ]